// assets
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';


// ==============================|| UTILITIES MENU ITEMS ||============================== //


const blog = {
    id: 'blog',
    title: 'Blog',
    type: 'item',
    url: 'https://www.talenteam.net/blog',
    external: true,
    target: true,
    icon: ModeCommentOutlinedIcon,
    // icon: IconDeviceTvOld,
    breadcrumbs: false
};

export default blog;
