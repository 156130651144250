// material-ui
import React from "react"
import {Typography} from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from '../../../../menu-items';
import NavItem from "./NavItem";

// ==============================|| SIDEBAR MENU LIST ||============================== //

function MenuList() {
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item}/>;
            case 'item':
                return <NavItem key={item.id} item={item} level={1}/>;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
}

export default MenuList;
