import { useSelector } from 'react-redux';
import React from "react"

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
// import ThemeProvider from "./theme";


// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';

// ==============================|| APP ||============================== //

function App() {
  const customization = useSelector((state) => state.customization);


  return (
      <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme(customization)}>
              {/* <ThemeProvider> */}
              <CssBaseline />
              <NavigationScroll>
                  <Routes />
              </NavigationScroll>
              {/* </ThemeProvider> */}
          </ThemeProvider>
      </StyledEngineProvider>
  );
}

export default App;
