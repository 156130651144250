import React from "react"
import PropTypes from 'prop-types';
// @mui
import {alpha, styled, useTheme} from '@mui/material/styles';
import {IconButton, InputAdornment, Link, OutlinedInput, Toolbar, Tooltip, Typography} from '@mui/material';
// component
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Iconify from '../../../components/iconify';
import customShadows from "../../../theme/customShadows";


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({theme}) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    // padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({theme}) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
        width: 240,
        boxShadow: customShadows.z8,
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
    numSelected: PropTypes.number,
    filterName: PropTypes.string,
    onFilterName: PropTypes.func,
};

export default function UserListToolbar({numSelected, filterName, onFilterName, handleArchiveCandidate}) {
    const theme = useTheme();
    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter',
                }),
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <StyledSearch
                    value={filterName}
                    onChange={onFilterName}
                    placeholder="Filter Candidates..."
                    startAdornment={
                        <InputAdornment position="start">
                            <Iconify icon="eva:search-fill" sx={{color: 'text.disabled', width: 20, height: 20}}/>
                        </InputAdornment>
                    }
                />
            )}

            {numSelected > 0 ? (
                <Tooltip title="Archive Selected Candidates">
                    <IconButton onClick={handleArchiveCandidate}>
                        <GroupRemoveIcon/>
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Watch Tutorial">
                    <Link target="_blank"
                          rel="noreferrer"
                          href="https://www.youtube.com/embed/O9up_dnKK5Y?si=jqy4n-eojLNlIOBh"
                          color="inherit">
                        <IconButton
                            sx={{"&:hover": {color: theme.palette.primary.dark}}}>
                            <OndemandVideoIcon/>
                        </IconButton>
                    </Link>
                </Tooltip>
            )}
        </StyledRoot>
    );
}
