import React from "react"
//
import MainOverride from './mainOverride';
import Card from "../theme/overrides/Card";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
    return Object.assign(
        Card(theme),
        // Table(theme),
        // Input(theme),
        // Paper(theme),
        // Button(theme),
        // Tooltip(theme),
        // Backdrop(theme),
        // Typography(theme),
        // Autocomplete(theme),
        MainOverride(theme)
    );
}
