import React, {forwardRef} from "react"
import PropTypes from 'prop-types';

// material-ui
import {useTheme} from '@mui/material/styles';
import {Card, CardContent, CardHeader, Divider, Typography} from '@mui/material';
import Paper from "@mui/material/Paper";
import LinearProgress from "@mui/material/LinearProgress";
import MuiTypography from "@mui/material/Typography";

// constant
const headerSX = {
    '& .MuiCardHeader-action': {mr: 0}
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentClass = '',
            contentSX = {},
            darkTitle,
            secondary,
            shadow,
            sx = {},
            title,
            isLoading,
            isCollapsed,
            ...others
        },
        ref
    ) => {
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...others}
                sx={{
                    border: border ? '1px solid' : 'none',
                    borderColor: theme.palette.info[200] + 25,
                    ':hover': {
                        boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...sx
                }}
            >
                {/* card header and action */}
                {title &&
                    <CardHeader
                        sx={headerSX}
                        title={darkTitle ? <Typography variant="h3">{title}</Typography> : title}
                        action={secondary}
                        subheader={<MuiTypography variant="h5" sx={{mt: 1}}>Hey there and welcome!</MuiTypography>}
                    />}

                {/* content & header divider */}
                {title && !isLoading && <Divider/>}
                {isLoading && (
                    <Paper>
                        <LinearProgress/>
                    </Paper>
                )}
                {/* card content */}
                {content && (
                    <CardContent sx={contentSX} className={contentClass}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    }
);

MainCard.propTypes = {
    border: PropTypes.bool,
    boxShadow: PropTypes.bool,
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    contentSX: PropTypes.object,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    shadow: PropTypes.string,
    sx: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
    isLoading: PropTypes.bool,
    isCollapsed: PropTypes.bool,
};

export default MainCard;
