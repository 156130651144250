import React, {lazy} from 'react';
// project imports
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import CandidatesPage from "../pages/candidates/CandidatesPage";

// dashboard routing
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard')));

// utilities routing
const OnboardingPage = Loadable(lazy(() => import('../pages/onboarding/Onboarding')));
// const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const AuthenticatedRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: 'onboarding',
            element: <OnboardingPage/>,
        },
        {
            path: 'candidates',
            element: <CandidatesPage/>,
        },
        {
            path: 'dashboard',
            element: <DashboardPage/>,
        },
        // {
        //   path: 'dashboard',
        //   children: [
        //     {
        //       path: 'default',
        //       element: <DashboardPage />,
        //     },
        //   ],
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-color',
        //             element: <UtilsColor/>,
        //         },
        //     ],
        // },
        // {
        //     path: 'utils',
        //     children: [
        //         {
        //             path: 'util-shadow',
        //             element: <UtilsShadow/>,
        //         },
        //     ],
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'tabler-icons',
        //             element: <UtilsTablerIcons/>,
        //         },
        //     ],
        // },
        // {
        //     path: 'icons',
        //     children: [
        //         {
        //             path: 'material-icons',
        //             element: <UtilsMaterialIcons/>,
        //         },
        //     ],
        // },
        // {
        //     path: 'sample-page',
        //     element: <SamplePage/>,
        // },
    ],
};

export default AuthenticatedRoutes;
