// assets
import CampaignSharpIcon from '@mui/icons-material/CampaignSharp';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';


// ==============================|| UTILITIES MENU ITEMS ||============================== //

// const marketing = {
//   id: 'product',
//   title: 'Product',
//   type: 'group',
//   children: [
//     {
//       id: 'product-updates',
//       title: 'Product Updates',
//       type: 'item',
//       target: true,
//       url: 'https://www.talenteam.net/',
//       icon: CampaignOutlinedIcon,
//       external: true,
//       // icon: IconDeviceTvOld,
//       breadcrumbs: false
//     },
//   ]
// };

const marketing = {
    id: 'product',
    title: 'Product Updates',
    type: 'item',
    url: 'https://www.talenteam.net/',
    divider: true,
    external: true,
    target: true,
    icon: CampaignOutlinedIcon,
    // icon: IconDeviceTvOld,
    breadcrumbs: false
};

export default marketing;
