import React, {useEffect, useState} from 'react'
import {filter} from 'lodash';
import moment from 'moment';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


import {sentenceCase} from 'change-case';
// @mui
import {
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    CircularProgress,
    Collapse,
    Container,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    InputLabel,
    Link,
    MenuItem,
    OutlinedInput,
    Paper,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
// components
// import {useNavigate} from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {useTheme} from "@mui/material/styles";

import * as Yup from "yup";
import {Formik} from "formik";
import MuiTypography from "@mui/material/Typography";
import {IconBell, IconBellOff} from "@tabler/icons";
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import {UserListHead, UserListToolbar} from '../../sections/@dashboard/user';
import useScriptRef from "../../hooks/useScriptRef";
import {API_URL} from "../../fetcher";

const TABLE_HEAD = [
    {id: 'name', label: 'Name', alignRight: false, hideable: false},
    {id: 'time_created', label: 'Time Created', alignRight: false},
    // {id: 'cv', label: 'CV', alignRight: false},
    // {id: 'verified', label: 'Verified', alignRight: false},
    // {id: 'status', label: 'Status', alignRight: false},
    {id: 'linkedin_profile_id', label: 'LinkedIn Profile', alignRight: false},
    {id: 'linkedin_profile_last_company', label: 'Current Company', alignRight: false},
    // {id: 'profile', label: 'Profile', alignRight: false},
    // {id: 'education', label: 'Education', alignRight: false},
    {id: 'linkedin_profile_last_company_location', label: 'Location', alignRight: false},
    {id: 'alerts_configured', label: 'Alerts', alignRight: false},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function CandidatesPage() {
    const theme = useTheme();
    const scriptedRef = useScriptRef();
    const [editingIndex, setEditingIndex] = useState(-1);
    const handleEditClick = (index) => {
        setEditingIndex(index);
    };
    const [isAlertsHovered, setIsAlertsHovered] = useState(false);

    // const navigate = useNavigate();
    const [candidates, setCandidates] = useState([]);
    const [hasLinkedinAccount, setHasLinkedinAccount] = useState([]);

    const [error, setError] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const [openMenu, setOpenMenu] = useState(null);
    const [order, setOrder] = useState('asc');

    const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');
    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch(`${API_URL}/views/candidates`);
            const body = await resp.json();

            if (body.status !== 200) {
                console.log("No users yet");
            }

            setCandidates(body.candidates);
            setIsLoading(false);
            setError(body.error);
            setHasLinkedinAccount(body.has_linkedin_account)
        };

        fetchData();
    }, []);

    const getLinkedinIdByCandidateId = (candidateId) => {
        const candidateList = candidates.filter((candidate) => candidate.candidate_id === candidateId)
        return candidateList[0].linkedin_profile_id
    };

    const handleCloseMenu = () => {
        setOpenMenu(null);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleArchiveCandidate = async () => {
        const resp = await fetch(`${API_URL}/views/archive_candidate`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({candidate_ids_to_archive: selectedCheckBoxes})
        });
        const body = await resp.json();
        if (body.status === 200) {
            setCandidates(body.candidates)
            setSelectedCheckBoxes([])
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = candidates.map((n) => n.candidate_id);
            setSelectedCheckBoxes(newSelecteds);
            return;
        }
        setSelectedCheckBoxes([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedCheckBoxes.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedCheckBoxes, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedCheckBoxes.slice(1));
        } else if (selectedIndex === selectedCheckBoxes.length - 1) {
            newSelected = newSelected.concat(selectedCheckBoxes.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selectedCheckBoxes.slice(0, selectedIndex), selectedCheckBoxes.slice(selectedIndex + 1));
        }
        setSelectedCheckBoxes(newSelected);
    };
    const handleFilterByName = (event) => {
        // setPage(0);
        setFilterName(event.target.value);
    };
    const filteredCandidates = applySortFilter(candidates, getComparator(order, orderBy), filterName);
    const isNotFound = !filteredCandidates.length && !!filterName;
    const tableCellStyle = {
        maxWidth: 130,
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderStyle: "border-box"
    }

    return (
        <>
            <Container maxWidth="2500px">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>Candidates</Typography>
                    <Tooltip
                        title="To activate the 'New Candidates' button, please reach out to our support team at support@talenteam.app.">
                        <Button variant="contained" sx={{"&:hover": {backgroundColor: theme.palette.grey[500]}}}
                                startIcon={<Iconify icon="eva:plus-fill"/>}>
                            New Candidate
                        </Button>
                    </Tooltip>
                </Stack>
                <Card>
                    <UserListToolbar numSelected={selectedCheckBoxes.length} filterName={filterName}
                                     onFilterName={handleFilterByName} handleArchiveCandidate={handleArchiveCandidate}
                    />
                    <Scrollbar>
                        <TableContainer sx={{minWidth: 800}}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={candidates.length}
                                    numSelected={selectedCheckBoxes.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {/* {filteredCandidates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map( */}
                                    {
                                        isLoading && (
                                            <TableRow>
                                                <TableCell align="center" colSpan={8}
                                                           sx={{py: 3}}>
                                                    <Paper
                                                        sx={{
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        <LinearProgress/>
                                                    </Paper>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                    {filteredCandidates.map(
                                        (row, index) => {
                                            const {
                                                candidate_id: candidateId,
                                                candidate_created_at: candidateCreatedAt,
                                                user_connector_id: userConnectorId,
                                                name,
                                                // profile_headline: profileHeadline,
                                                linkedin_id: linkedinId,
                                                should_alert: shouldAlert,
                                                linkedin_profile_id: profileLinkedinId,
                                                linkedin_profile_last_position: position,
                                                linkedin_profile_last_company: company,
                                                linkedin_profile_last_company_location: location,
                                                linkedin_profile_last_education: education,
                                                // candidate_source_status: status, // TODO: status here should be for all sources and destinations
                                                linkedin_profile_image_url: avatarUrl,
                                            } = row;
                                            const selectedCandidate = selectedCheckBoxes.indexOf(candidateId) !== -1;

                                            const handleMouseOver = () => {
                                                setIsAlertsHovered(true);
                                                // Additional logic
                                            };

                                            const handleMouseOut = () => {
                                                setIsAlertsHovered(false);
                                                // Additional logic
                                            };
                                            return (
                                                <>
                                                    <TableRow hover key={candidateId} tabIndex={0} role="checkbox"
                                                              selected={selectedCandidate}
                                                    >
                                                        <TableCell sx={{pl: 2, pr: 0}}>
                                                            <IconButton
                                                                aria-label="expand row"
                                                                size="small"
                                                                onClick={() => setSelectedRowIndex(candidateId !== selectedRowIndex ? candidateId : null)}>
                                                                {selectedRowIndex === candidateId ?
                                                                    <KeyboardArrowDownIcon/> :
                                                                    <KeyboardArrowRightIcon/>}
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell sx={{pl: 0, pr: 1}}>
                                                            <Checkbox
                                                                checked={selectedCandidate}
                                                                onChange={(event) => handleClick(event, candidateId)}/>
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" padding="none">
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                <Avatar alt={name} src={avatarUrl}/>
                                                                <Typography variant="subtitle2" noWrap>
                                                                    {name}
                                                                </Typography>
                                                            </Stack>
                                                        </TableCell>
                                                        <TableCell
                                                            align="left"
                                                            type="datetime">
                                                            {candidateCreatedAt}
                                                        </TableCell>
                                                        {/* <TableCell component="th" scope="row" padding="none"> */}
                                                        {/*    <Typography variant="subtitle2" noWrap> */}
                                                        {/*        {candidateCreatedAt} */}
                                                        {/*    </Typography> */}
                                                        {/* </TableCell> */}
                                                        {/* <TableCell align="left"> */}
                                                        {/*    {verified ? 'Yes' : 'No'} */}
                                                        {/* </TableCell> */}
                                                        <TableCell sx={{pl: 6}}>
                                                            <>
                                                                {!hasLinkedinAccount && (
                                                                    <Tooltip
                                                                        title='Set up your LinkedIn account to access
                                                                    LinkedIn features.'>
                                                                        <PersonOffIcon sx={{
                                                                            ml: 0.5,
                                                                            color: theme.palette.grey[500]
                                                                        }}/>

                                                                    </Tooltip>
                                                                )}
                                                                {hasLinkedinAccount && (
                                                                    <Formik
                                                                        initialValues={{
                                                                            profileLinkedinId,
                                                                            submit: null
                                                                        }}
                                                                        validationSchema={Yup.object().shape({
                                                                            profileLinkedinId: Yup.string().max(100).required('Linkedin ID cannot be blank'),
                                                                        })}
                                                                        onSubmit={async (values, {
                                                                            setErrors,
                                                                            setStatus,
                                                                            setSubmitting,
                                                                            resetForm,
                                                                        }) => {
                                                                            if (index !== editingIndex) {
                                                                                return
                                                                            }
                                                                            try {
                                                                                if (scriptedRef.current) {
                                                                                    const resp = await fetch(`${API_URL}/views/update_linkedin_profile_id`, {
                                                                                        method: 'POST',
                                                                                        headers: {
                                                                                            'Accept': 'application/json',
                                                                                            'Content-Type': 'application/json'
                                                                                        },
                                                                                        body: JSON.stringify({
                                                                                            user_connector_id: userConnectorId,
                                                                                            candidate_id: candidateId,
                                                                                            linkedin_id: linkedinId,
                                                                                            linkedin_profile_id: values.profileLinkedinId
                                                                                        })
                                                                                    });
                                                                                    const body = await resp.json();
                                                                                    if (body.status === 200) {
                                                                                        setStatus({success: true});
                                                                                        setSubmitting(false);

                                                                                        // handleLinkedinId(body.candidate)
                                                                                        setCandidates(body.candidates)

                                                                                        // const {candidate_linkedin} = body
                                                                                        // handleLinkedinId(body.candidate, index)

                                                                                        // setProfileLinkedinIds()
                                                                                        // setGreenhouseConnectors(body.greenhouse_connectors)
                                                                                        resetForm({
                                                                                            values: {
                                                                                                profileLinkedinId: body.candidate.linkedin_profile_id,
                                                                                                submit: null
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    if (body.status !== 200) {
                                                                                        setStatus({success: false});
                                                                                        setErrors({submit: body.data.message});
                                                                                        setSubmitting(false);
                                                                                    }
                                                                                }
                                                                            } catch (err) {
                                                                                console.error(err);
                                                                                if (scriptedRef.current) {
                                                                                    setStatus({success: false});
                                                                                    setErrors({submit: err.message});
                                                                                    setSubmitting(false);
                                                                                }
                                                                            }
                                                                        }}
                                                                    >
                                                                        {({
                                                                              errors,
                                                                              handleBlur,
                                                                              handleChange,
                                                                              handleSubmit,
                                                                              isSubmitting,
                                                                              touched,
                                                                              values
                                                                          }) => (
                                                                            <form noValidate
                                                                                  onSubmit={handleSubmit}>
                                                                                <div style={{
                                                                                    display: 'flex',
                                                                                    justifyContent: 'flex-start',
                                                                                    alignItems: 'center'
                                                                                }}>
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <>
                                                                                                <span
                                                                                                    style={{fontWeight: 'bold'}}>
                                                                                                    {position}
                                                                                                </span>
                                                                                                {position && (
                                                                                                    <br/>
                                                                                                )}

                                                                                                <span>{profileLinkedinId ? `Education: ${education}` : "Enter the candidate's LinkedIn profile ID first in order to view their LinkedIn details."}</span>
                                                                                            </>}>
                                                                                        {profileLinkedinId ? (
                                                                                            <Link target="_blank"
                                                                                                  rel="noreferrer"
                                                                                                  href={`https://www.linkedin.com/in/${values.profileLinkedinId}`}
                                                                                                  color="inherit">
                                                                                                <LinkedInIcon
                                                                                                    sx={{
                                                                                                        color: theme.palette.primary.main,
                                                                                                        mt: 1,
                                                                                                        mr: 3,
                                                                                                        ml: -4,
                                                                                                        "&:hover": {color: theme.palette.primary.dark}
                                                                                                    }}
                                                                                                />
                                                                                            </Link>
                                                                                        ) : (
                                                                                            <LinkedInIcon
                                                                                                sx={{
                                                                                                    color: theme.palette.grey[500],
                                                                                                    mt: 1,
                                                                                                    mr: 3,
                                                                                                    ml: -4,
                                                                                                    // "&:hover": {color: theme.palette.primary.dark}
                                                                                                }}/>
                                                                                        )}
                                                                                    </Tooltip>
                                                                                    <FormControl
                                                                                        error={Boolean(touched.link && errors.profileLinkedinId)}
                                                                                        sx={{
                                                                                            ...theme.typography.customInput,
                                                                                            width: 220
                                                                                        }}
                                                                                        disabled={index !== editingIndex}>
                                                                                        <InputLabel
                                                                                            htmlFor="outlined-adornment-email-register">
                                                                                            LinkedIn Profile ID
                                                                                        </InputLabel>
                                                                                        {index === editingIndex && (
                                                                                            <OutlinedInput
                                                                                                id="outlined-adornment-email-register"
                                                                                                type="profileLinkedinId"
                                                                                                value={values.profileLinkedinId}
                                                                                                disabled={index !== editingIndex}
                                                                                                name="profileLinkedinId"
                                                                                                onBlur={handleBlur}
                                                                                                onChange={handleChange}
                                                                                                inputProps={{}}
                                                                                                endAdornment={(
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <IconButton
                                                                                                            // aria-label="expand row"
                                                                                                            size="small"
                                                                                                            type="submit"
                                                                                                            disabled={values.profileLinkedinId === getLinkedinIdByCandidateId(candidateId) || isSubmitting || errors.profileLinkedinId}
                                                                                                            // onClick={() => handleSubmitLinkedinId(userConnectorId, candidateSourceId, linkedinId, profileLinkedinId)}
                                                                                                            sx={{
                                                                                                                color: theme.palette.primary.main,
                                                                                                                "&:hover": {color: theme.palette.primary.dark},
                                                                                                            }}>
                                                                                                            {isSubmitting ?
                                                                                                                // <SendIcon/> :
                                                                                                                // <CircularProgress/>
                                                                                                                <CircularProgress/> :
                                                                                                                <SendIcon/>
                                                                                                            }
                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                        {index !== editingIndex && (
                                                                                            <OutlinedInput
                                                                                                id="outlined-adornment-email-register"
                                                                                                type="profileLinkedinId"
                                                                                                value={values.profileLinkedinId}
                                                                                                disabled={index !== editingIndex}
                                                                                                name="profileLinkedinId"
                                                                                                onBlur={handleBlur}
                                                                                                onChange={handleChange}
                                                                                                inputProps={{}}
                                                                                                endAdornment={(
                                                                                                    <InputAdornment
                                                                                                        position="end">
                                                                                                        <IconButton
                                                                                                            aria-label="expand row"
                                                                                                            size="small"
                                                                                                            type="button"
                                                                                                            disabled={!hasLinkedinAccount}
                                                                                                            onClick={() => handleEditClick(index)}
                                                                                                            sx={{
                                                                                                                color: theme.palette.primary.main,
                                                                                                                "&:hover": {color: theme.palette.primary.dark},
                                                                                                            }}>
                                                                                                            {/* <Tooltip */}
                                                                                                            {/*    title={hasLinkedinAccount ? "Sign Up to LinkedIn in order to use LinkedIn features." : "Press on edit in order to updates' the candidate's LinkedIn Profile Id"}> */}
                                                                                                            {/*    <EditIcon/> */}
                                                                                                            {/* </Tooltip> */}
                                                                                                            <EditIcon/>
                                                                                                        </IconButton>
                                                                                                    </InputAdornment>
                                                                                                )}
                                                                                            />
                                                                                        )}
                                                                                        {touched.profileLinkedinId && errors.profileLinkedinId && (
                                                                                            <FormHelperText error
                                                                                                            id="standard-weight-helper-text--register">
                                                                                                {errors.profileLinkedinId}
                                                                                            </FormHelperText>
                                                                                        )}
                                                                                    </FormControl>
                                                                                </div>
                                                                                {errors.submit && (
                                                                                    <Box sx={{ml: 6}}>
                                                                                        <FormHelperText error>
                                                                                            {errors.submit}
                                                                                        </FormHelperText>
                                                                                    </Box>
                                                                                )}
                                                                            </form>
                                                                        )}
                                                                    </Formik>
                                                                )}
                                                            </>
                                                        </TableCell>
                                                        <TableCell align="left">{company}</TableCell>
                                                        <TableCell align="left">{location}</TableCell>
                                                        <TableCell align="left">
                                                            <Formik
                                                                initialValues={{shouldAlert}}
                                                                validationSchema={Yup.object().shape({
                                                                    shouldAlert: Yup.boolean(),
                                                                })}
                                                                onSubmit={async (values, {
                                                                    setErrors,
                                                                    setStatus,
                                                                    setSubmitting,
                                                                    resetForm,
                                                                }) => {
                                                                    try {
                                                                        if (scriptedRef.current) {
                                                                            const resp = await fetch(`${API_URL}/views/update_should_alert`, {
                                                                                method: 'POST',
                                                                                headers: {
                                                                                    'Accept': 'application/json',
                                                                                    'Content-Type': 'application/json'
                                                                                },
                                                                                body: JSON.stringify({
                                                                                    user_connector_id: userConnectorId,
                                                                                    candidate_id: candidateId,
                                                                                    linkedin_id: linkedinId,
                                                                                    should_alert: !values.shouldAlert
                                                                                })
                                                                            });
                                                                            const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
                                                                            await sleep(700);
                                                                            const body = await resp.json();
                                                                            if (body.status === 200) {
                                                                                setStatus({success: true});
                                                                                setSubmitting(false);
                                                                                setIsAlertsHovered(false);
                                                                                setCandidates(body.candidates)
                                                                                resetForm({
                                                                                    values: {
                                                                                        shouldAlert: body.candidate.should_alert,
                                                                                    }
                                                                                })
                                                                            }
                                                                            if (body.status !== 200) {
                                                                                setStatus({success: false});
                                                                                setErrors({submit: body.data.message});
                                                                                setSubmitting(false);
                                                                            }
                                                                        }
                                                                    } catch (err) {
                                                                        console.error(err);
                                                                        if (scriptedRef.current) {
                                                                            setStatus({success: false});
                                                                            setErrors({submit: err.message});
                                                                            setSubmitting(false);
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {({
                                                                      errors,
                                                                      handleSubmit,
                                                                      isSubmitting,
                                                                  }) => (
                                                                    <form noValidate onSubmit={handleSubmit}>
                                                                        {isSubmitting && <CircularProgress/>}
                                                                        {!isSubmitting && !linkedinId && (
                                                                            <Tooltip
                                                                                title="Add LinkedIn Profile ID in order to set up LinkedIn Alerts.">
                                                                                <div style={{marginLeft: 5}}>
                                                                                    <IconBellOff
                                                                                        disabled
                                                                                        stroke={1.5}
                                                                                        size="25"/>
                                                                                </div>
                                                                            </Tooltip>
                                                                            // <IconButton
                                                                            //     aria-disabled
                                                                            //     disabled={true}
                                                                            //     onSubmit={handleSubmit}
                                                                            //     aria-haspopup="true"
                                                                            //     color="inherit"
                                                                            // >
                                                                            // </IconButton>
                                                                        )}
                                                                        {!isSubmitting && linkedinId && !shouldAlert && (
                                                                            <Tooltip
                                                                                title="Click to enable LinkedIn Alerts.">
                                                                                <IconButton
                                                                                    disabled={!linkedinId || isSubmitting || errors.shouldAlert}
                                                                                    type="submit"
                                                                                    name="shouldAlert"
                                                                                    onSubmit={handleSubmit}
                                                                                    onMouseOver={handleMouseOver}
                                                                                    onMouseOut={handleMouseOut}
                                                                                    sx={{
                                                                                        ...theme.typography.commonAvatar,
                                                                                        ...theme.typography.mediumAvatar,
                                                                                        transition: 'all .2s ease-in-out',
                                                                                        background: theme.palette.grey[500],
                                                                                        color: theme.palette.secondary.light,
                                                                                        '&[aria-controls="menu-list-grow"],&:hover': {
                                                                                            background: theme.palette.secondary.main,
                                                                                            color: theme.palette.secondary.light
                                                                                        }
                                                                                    }}
                                                                                    // aria-controls={linkedinId && shouldAlert !== 'False' ? 'menu-list-grow' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    // onClick={handleAlerts}
                                                                                    color="inherit"
                                                                                >
                                                                                    {isAlertsHovered ?
                                                                                        <IconBell/> :
                                                                                        <IconBellOff/>}
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {!isSubmitting && linkedinId && shouldAlert && (
                                                                            <Tooltip
                                                                                title="Click to disable LinkedIn Alerts.">
                                                                                <IconButton
                                                                                    type="submit"
                                                                                    name="shouldAlert"
                                                                                    onMouseOver={handleMouseOver}
                                                                                    onMouseOut={handleMouseOut}
                                                                                    sx={{
                                                                                        ...theme.typography.commonAvatar,
                                                                                        ...theme.typography.mediumAvatar,
                                                                                        transition: 'all x§s ease-in-out',
                                                                                        background: theme.palette.secondary.main,
                                                                                        color: theme.palette.secondary.light,
                                                                                        '&[aria-controls="menu-list-grow"],&:hover': {
                                                                                            background: theme.palette.grey[500],
                                                                                            color: theme.palette.secondary.light,
                                                                                        }
                                                                                    }}
                                                                                    // aria-controls={linkedinId && shouldAlert !== 'False' ? 'menu-list-grow' : undefined}
                                                                                    // aria-haspopup="true"
                                                                                    // onClick={handleAlerts}
                                                                                    color="inherit"
                                                                                >
                                                                                    {isAlertsHovered ?
                                                                                        <IconBellOff/> :
                                                                                        <IconBell/>
                                                                                    }
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                        {/* </IconButton> */}
                                                                        {errors.submit && (
                                                                            <Box sx={{ml: 6}}>
                                                                                <FormHelperText
                                                                                    error>{errors.submit}</FormHelperText>
                                                                            </Box>
                                                                        )}
                                                                    </form>
                                                                )}
                                                            </Formik>

                                                            {/* Linkeding ends bell starts */}

                                                        </TableCell>
                                                        {/* <TableCell align="right"> */}
                                                        {/*    <IconButton size="large" color="inherit" */}
                                                        {/*                onClick={handleOpenMenu}> */}
                                                        {/*        <Iconify icon="eva:more-vertical-fill"/> */}
                                                        {/*    </IconButton> */}
                                                        {/* </TableCell> */}
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell
                                                            style={{
                                                                paddingBottom: 0,
                                                                paddingTop: 0
                                                            }}
                                                            colSpan={11}
                                                        >
                                                            <Collapse
                                                                in={selectedRowIndex === candidateId}
                                                                timeout="auto"
                                                                unmountOnExit>
                                                                <Typography
                                                                    variant="h4"
                                                                    gutterBottom
                                                                    mt={3}
                                                                    mb={2}
                                                                >
                                                                    Companies
                                                                </Typography>
                                                                <Box sx={{margin: 1}}>
                                                                    <Table aria-label="purchases">
                                                                        <TableHead>
                                                                            <TableRow>
                                                                                <TableCell>Company</TableCell>
                                                                                <TableCell>Position</TableCell>
                                                                                <TableCell>Upload Date</TableCell>
                                                                                <TableCell>Email Date</TableCell>
                                                                                <TableCell>Platform</TableCell>
                                                                                <TableCell>Stage</TableCell>
                                                                                <TableCell>Status</TableCell>
                                                                                <TableCell>Email Subject</TableCell>
                                                                                {/* <TableCell>Is Verified</TableCell> */}
                                                                                <TableCell>CV</TableCell>
                                                                            </TableRow>
                                                                        </TableHead>

                                                                        {!row.destinations.length ? (
                                                                            <Typography
                                                                                variant="h5"
                                                                                gutterBottom
                                                                                mt={3}
                                                                                mb={5}
                                                                            >
                                                                                Please sign in with your Greenhouse
                                                                                account to view the distribution status
                                                                                updates for companies.
                                                                            </Typography>
                                                                        ) : (
                                                                            row.destinations.map(
                                                                                (destinationRow, destinationIndex) => {
                                                                                    const {
                                                                                        candidate_source_id: candidateSourceId,
                                                                                        cv_attachment_id: cvAttachmentId,
                                                                                        cv_file_name: cvFileName,
                                                                                        source_name: sourceName,
                                                                                        email_subject: emailSubject,
                                                                                        email_datetime: emailDatetime,
                                                                                        candidate_destination_id: candidateDestinationId,
                                                                                        destination_name: destinationName,
                                                                                        destination_upload_datetime: destinationUploadDatetime,
                                                                                        destination_company_name: destinationCompanyName,
                                                                                        destination_position_name: destinationPositionName,
                                                                                        destination_candidate_stage: destinationCandidateStage,
                                                                                        destination_candidate_status: destinationCandidateStatus,
                                                                                    } = destinationRow;
                                                                                    return (
                                                                                        <TableRow hover
                                                                                                  key={candidateDestinationId}
                                                                                                  tabIndex={0}
                                                                                                  role="checkbox"
                                                                                                  sx={{"& td": {border: destinationIndex === row.destinations.length - 1 ? 0 : ''}}}>
                                                                                            <TableCell
                                                                                                align="left">
                                                                                                {destinationCompanyName}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="left">{destinationPositionName}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="left"
                                                                                                type="date">
                                                                                                {destinationUploadDatetime || 'Pending'}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="left"
                                                                                                type="date">
                                                                                                {emailDatetime}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                style={tableCellStyle}
                                                                                                align="left">
                                                                                                {destinationName}
                                                                                            </TableCell>
                                                                                            <TableCell align="left">
                                                                                                {destinationCandidateStage}
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                align="left">
                                                                                                <Label
                                                                                                    color={(destinationCandidateStatus === 'rejected' && 'error') || 'success'}>
                                                                                                    {sentenceCase(destinationCandidateStatus)}
                                                                                                </Label>
                                                                                            </TableCell>
                                                                                            <TableCell
                                                                                                style={tableCellStyle}
                                                                                                align="left">
                                                                                                {emailSubject}
                                                                                            </TableCell>
                                                                                            {/*<TableCell*/}
                                                                                            {/*    style={tableCellStyle}*/}
                                                                                            {/*    align="left">*/}
                                                                                            {/*    {sourceName}*/}
                                                                                            {/*</TableCell>*/}
                                                                                            {/* <TableCell */}
                                                                                            {/*    align="left"> */}
                                                                                            {/*    {destinationVerified ? 'Yes' : 'No'} */}
                                                                                            {/* </TableCell> */}
                                                                                            <TableCell align="left">
                                                                                                {cvAttachmentId && (
                                                                                                    <Tooltip
                                                                                                        title={`Download '${cvFileName}'.`}>

                                                                                                        <IconButton
                                                                                                            size="small"
                                                                                                            type="submit"
                                                                                                        >
                                                                                                            <a href={`/views/download_cv?candidate_source_id=${candidateSourceId}&user_connector_id=${userConnectorId}`}>
                                                                                                                <FileDownloadOutlinedIcon
                                                                                                                    sx={{
                                                                                                                        color: theme.palette.primary.main,
                                                                                                                        "&:hover": {color: theme.palette.primary.dark},
                                                                                                                    }}
                                                                                                                />
                                                                                                            </a>
                                                                                                        </IconButton>
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                                {!cvAttachmentId && (
                                                                                                    <Tooltip
                                                                                                        title='No CV was found in the email.'>
                                                                                                        <ContactPageIcon
                                                                                                            sx={{
                                                                                                                ml: 0.5,
                                                                                                                color: theme.palette.grey[500]
                                                                                                            }}/>
                                                                                                    </Tooltip>

                                                                                                )}
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                }))}
                                                                    </Table>
                                                                </Box>
                                                            </Collapse></TableCell>
                                                    </TableRow>
                                                    {
                                                        error && !isLoading && (
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell align="center" colSpan={8}
                                                                               sx={{py: 3}}>
                                                                        <Paper
                                                                            sx={{
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography variant="body2" paragraph>
                                                                                <strong>No Candidates</strong>
                                                                            </Typography>
                                                                            <Typography variant="body2">
                                                                                {error}.
                                                                            </Typography>
                                                                        </Paper>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        )
                                                    }
                                                    {
                                                        isNotFound && (
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell align="center" colSpan={8}
                                                                               sx={{py: 3}}>
                                                                        <Paper
                                                                            sx={{
                                                                                textAlign: 'center',
                                                                            }}
                                                                        >
                                                                            <Typography variant="h6" paragraph>
                                                                                Not found
                                                                            </Typography>

                                                                            <Typography variant="body2">
                                                                                No results found for &nbsp;
                                                                                <strong>&quot;{filterName}&quot;</strong>.
                                                                                <br/> Try checking for typos or
                                                                                using
                                                                                complete
                                                                                words.
                                                                            </Typography>
                                                                        </Paper>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        )
                                                    }
                                                </>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    {
                        candidates.length === 0 && !isLoading && (
                            <MuiTypography variant="h5" mt={1} fontWeight="800"
                                           sx={{textAlign: "center", pb: 10}} flexGrow='5'>
                                No Candidates.
                            </MuiTypography>
                        )
                    }
                </Card>
            </Container>

            <Popover
                open={Boolean(openMenu)}
                anchorEl={openMenu}
                onClose={handleCloseMenu}
                anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem>
                    <Iconify icon="eva:edit-fill" sx={{mr: 2}}/>
                    Edit
                </MenuItem>

                <MenuItem sx={{color: 'error.main'}}>
                    <PersonRemoveIcon sx={{mr: 2}}/>
                    Archive
                </MenuItem>
            </Popover>
        </>
    );
}
