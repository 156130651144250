// project imports
import React, {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import Customization from '../Customization';
import {API_URL} from "../../fetcher";

// ==============================|| MINIMAL LAYOUT ||============================== //

function MinimalLayout() {
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async () => {
            const resp = await fetch(`${API_URL}/auth/validate_anonymous`);
            const body = await resp.json();

            if (body.status !== 200) {
                navigate('../onboarding', {replace: true});
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <Outlet/>
            {/*<Customization/>*/}
        </>
    );
}

export default MinimalLayout;
