import React, {useEffect, useRef, useState} from "react"

import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';

// material-ui
import {useTheme} from '@mui/material/styles';
import {
    Avatar,
    Box,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import {IconLogout, IconSettings, IconUser} from '@tabler/icons';
import MuiTypography from "@mui/material/Typography";
import PropTypes from "prop-types";
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';
import {API_URL} from "../../../../fetcher";

// assets

// ==============================|| PROFILE MENU ||============================== //

function ProfileSection({user}) {
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const [sdm, setSdm] = useState(true);
    const [value, setValue] = useState('');
    const [notification, setNotification] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState(false);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);
    //
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const resp = await fetch("/views/user");
    //         const body = await resp.json();
    //
    //         if (body.status !== 200) {
    //             console.log("Couldn't get user");
    //         }
    //
    //         setUser(body.data);
    //     };
    //     fetchData();
    // }, []);
    const handleLogout = async () => {
        try {
            const resp = await fetch(`${API_URL}/auth/logout`);
            const body = await resp.json();

            if (body.status !== 200) {
                setIsLoggedIn(false);
                navigate('../signup', {replace: true});
            } else {
                setIsLoggedIn(true);
            }
        } catch (error) {
            console.log("error", error);
            navigate('../signup', {replace: true});

        }
        navigate('../login', {replace: true});
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleListItemClick = (event, index, route = '') => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Chip
                sx={{
                    height: '48px',
                    alignItems: 'center',
                    borderRadius: '27px',
                    transition: 'all .2s ease-in-out',
                    borderColor: theme.palette.primary.light,
                    backgroundColor: theme.palette.primary.contrastText,
                    '&[aria-controls="menu-list-grow"], &:hover': {
                        borderColor: theme.palette.primary.contrastText,
                        background: `${theme.palette.secondary.dark}!important`,
                        color: theme.palette.primary.contrastText,
                        textColor: theme.palette.primary.contrastText,
                        '& svg': {
                            stroke: theme.palette.primary.light
                        }
                    },
                    '& .MuiChip-label': {
                        lineHeight: 0
                    }
                }}
                icon={
                    <Avatar
                        src={user.image_url}
                        sx={{
                            ...theme.typography.mediumAvatar,
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer',
                        }}
                        ref={anchorRef}
                        aria-controls={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        // color="inherit"
                    />
                }
                label={
                    <div style={{display: 'flex', gap: '10px'}}>
                        {user.name && (
                            <MuiTypography
                                color="inherit"
                                style={{lineHeight: "16px"}} variant="subtitle2">
                                {user.name},
                                <br style={{display: 'block', lineHeight: '100px', margin: '100px 10px'}}/>
                                {user.org_display_name}
                            </MuiTypography>
                        )}
                        <IconSettings style={{marginTop: '4px'}} stroke={1.5} size="1.5rem"
                                      color={theme.palette.primary.main}/>
                    </div>
                }
                variant="outlined"
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                color="primary"
            />
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({TransitionProps}) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow
                                          shadow={theme.shadows[16]}>
                                    <Box sx={{p: 2}}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Hello,</Typography>
                                                <Typography component="span" variant="h4" sx={{fontWeight: 400}}>
                                                    {user.name}
                                                </Typography>
                                            </Stack>
                                            <Typography variant="subtitle2">Admin</Typography>
                                        </Stack>
                                        {/*<OutlinedInput*/}
                                        {/*    sx={{width: '100%', pr: 1, pl: 2, my: 2}}*/}
                                        {/*    id="input-search-profile"*/}
                                        {/*    value={value}*/}
                                        {/*    onChange={(e) => setValue(e.target.value)}*/}
                                        {/*    placeholder="Search profile options"*/}
                                        {/*    startAdornment={*/}
                                        {/*        <InputAdornment position="start">*/}
                                        {/*            <IconSearch stroke={1.5} size="1rem"*/}
                                        {/*                        color={theme.palette.grey[500]}/>*/}
                                        {/*        </InputAdornment>*/}
                                        {/*    }*/}
                                        {/*    aria-describedby="search-helper-text"*/}
                                        {/*    inputProps={{*/}
                                        {/*        'aria-label': 'weight'*/}
                                        {/*    }}*/}
                                        {/*/>*/}
                                        <Divider/>
                                    </Box>
                                    {/*<PerfectScrollbar*/}
                                    {/*    style={{height: '100%', maxHeight: 'calc(100vh - 250px)', overflowX: 'hidden'}}>*/}
                                        <Box>
                                            {/* <UpgradePlanCard /> */}
                                            {/* <Divider /> */}
                                            {/*                    <Card */}
                                            {/*  sx={{ */}
                                            {/*    bgcolor: theme.palette.primary.light, */}
                                            {/*    my: 2 */}
                                            {/*  }} */}
                                            {/* > */}
                                            {/*        <CardContent> */}
                                            {/*          <Grid container spacing={3} direction="column"> */}
                                            {/*            <Grid item> */}
                                            {/*              <Grid item container alignItems="center" justifyContent="space-between"> */}
                                            {/*                <Grid item> */}
                                            {/*                  <Typography variant="subtitle1">Start DND Mode</Typography> */}
                                            {/*              </Grid> */}
                                            {/*                <Grid item> */}
                                            {/*                  <Switch */}
                                            {/*  color="primary" */}
                                            {/*  checked={sdm} */}
                                            {/*  onChange={(e) => setSdm(e.target.checked)} */}
                                            {/*  name="sdm" */}
                                            {/*  size="small" */}
                                            {/* /> */}
                                            {/*              </Grid> */}
                                            {/*            </Grid> */}
                                            {/*          </Grid> */}
                                            {/*            <Grid item> */}
                                            {/*              <Grid item container alignItems="center" justifyContent="space-between"> */}
                                            {/*                <Grid item> */}
                                            {/*                  <Typography variant="subtitle1">Allow Notifications</Typography> */}
                                            {/*              </Grid> */}
                                            {/*                <Grid item> */}
                                            {/*                  <Switch */}
                                            {/*  checked={notification} */}
                                            {/*  onChange={(e) => setNotification(e.target.checked)} */}
                                            {/*  name="sdm" */}
                                            {/*  size="small" */}
                                            {/* /> */}
                                            {/*              </Grid> */}
                                            {/*            </Grid> */}
                                            {/*          </Grid> */}
                                            {/*        </Grid> */}
                                            {/*      </CardContent> */}
                                            {/* </Card> */}
                                            {/* <Divider /> */}
                                            <List
                                                component="nav"
                                                style={{ marginTop: '-15px'}}
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 350,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {/*<ListItemButton*/}
                                                {/*    sx={{borderRadius: `${customization.borderRadius}px`}}*/}
                                                {/*    selected={selectedIndex === 0}*/}
                                                {/*    onClick={(event) => handleListItemClick(event, 0, '#')}*/}
                                                {/*>*/}
                                                    {/*<ListItemIcon>*/}
                                                    {/*    <IconSettings stroke={1.5} size="1.3rem"/>*/}
                                                    {/*</ListItemIcon>*/}
                                                    {/*<ListItemText primary={<Typography variant="body2">Account*/}
                                                    {/*    Settings</Typography>}/>*/}
                                                {/*</ListItemButton>*/}
                                                {/*<ListItemButton*/}
                                                {/*    sx={{borderRadius: `${customization.borderRadius}px`}}*/}
                                                {/*    selected={selectedIndex === 1}*/}
                                                {/*    onClick={(event) => handleListItemClick(event, 1, '#')}*/}
                                                {/*>*/}
                                                {/*    <ListItemIcon>*/}
                                                {/*        <IconUser stroke={1.5} size="1.3rem"/>*/}
                                                {/*    </ListItemIcon>*/}
                                                {/*    <ListItemText*/}
                                                {/*        primary={*/}
                                                {/*            <Grid container spacing={1} justifyContent="space-between">*/}
                                                {/*                <Grid item>*/}
                                                {/*                    <Typography variant="body2">Social Profile</Typography>*/}
                                                {/*                </Grid>*/}
                                                {/*                <Grid item>*/}
                                                {/*                    <Chip*/}
                                                {/*                        label="02"*/}
                                                {/*                        size="small"*/}
                                                {/*                        sx={{*/}
                                                {/*                            bgcolor: theme.palette.warning.dark,*/}
                                                {/*                            color: theme.palette.background.default*/}
                                                {/*                        }}*/}
                                                {/*                    />*/}
                                                {/*                </Grid>*/}
                                                {/*            </Grid>*/}
                                                {/*        }*/}
                                                {/*    />*/}
                                                {/*</ListItemButton>*/}
                                                <ListItemButton
                                                    sx={{
                                                        borderRadius: `${customization.borderRadius}px`
                                                }}
                                                    selected={selectedIndex === 4}
                                                    onClick={handleLogout}
                                                >
                                                    <ListItemIcon>
                                                        <IconLogout stroke={1.5} size="1.3rem"/>
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={<Typography variant="body2">Logout</Typography>}/>
                                                </ListItemButton>
                                            </List>
                                        </Box>
                                    {/*</PerfectScrollbar>*/}
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}


ProfileSection.propTypes = {
    user: PropTypes.objectOf(PropTypes.any),
};

ProfileSection.defaultProps = {
    user: {}
};


export default ProfileSection;
