// material-ui
import React from "react"
import logo from '../assets/images/logo.svg';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';

 *
 */

// ==============================|| LOGO SVG ||============================== //

function Logo() {
    return (
        <div>
            <img src={logo} alt="TalenTeam" width="110"/>
        </div>
    );
}

export default Logo;
