// assets
import {IconDashboard, IconUser} from '@tabler/icons';
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";

// constant
const icons = { IconDashboard, IconUser };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

// const management = {
//   id: 'management',
//   title: 'Management',
//   type: 'group',
//   children: [
//      {
//       id: 'candidates',
//       title: 'Candidates',
//       type: 'item',
//       url: '/candidates',
//       icon: icons.IconUser,
//       breadcrumbs: false
//     }
//   ]
// };

const management = {
    id: 'candidates',
    title: 'Candidates',
    type: 'item',
    url: '/candidates',
    icon: icons.IconUser,
        divider: true,
    breadcrumbs: false
};

export default management;
