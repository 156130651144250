import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react'

// material-ui
import {useTheme} from '@mui/material/styles';
import {Avatar, Box, ButtonBase} from '@mui/material';

// project imports
import {IconMenu2} from '@tabler/icons';
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';
import {API_URL} from "../../../fetcher";

// assets

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

function Header({handleLeftDrawerToggle}) {
    const theme = useTheme();
    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch(`${API_URL}/views/user`);
            const body = await resp.json();

            if (body.status !== 200) {
                console.log("Couldn't get user");
            }

            setUser(body.data);
        };
        fetchData();
    }, []);


    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{display: {xs: 'none', md: 'block'}, flexGrow: 3}}>
                    <LogoSection/>
                </Box>
                <ButtonBase sx={{borderRadius: '12px', overflow: 'hidden'}}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem"/>
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{flexGrow: 1}}/>
            <Box sx={{flexGrow: 1}}/>

            {/* notification & profile */}
            <NotificationSection/>
            <ProfileSection user={user}/>
        </>
    );
}

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
