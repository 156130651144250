import dashboard from './dashboard';
import onboarding from "./onboarding";
import marketing from "./marketing";
import management from "./management";
import blog from "./blog";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [onboarding, dashboard, utilities, pages, other]
  items: [onboarding, management, dashboard, marketing, blog]
};

export default menuItems;
