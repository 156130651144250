// assets
import {IconDashboard, IconUser} from '@tabler/icons';

// constant
const icons = { IconDashboard, IconUser };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

// const dashboard = {
//   id: 'analytics',
//   title: 'Analytics',
//   type: 'group',
//   children: [
//     {
//       id: 'default',
//       title: 'Dashboard',
//       type: 'item',
//       url: '/dashboard',
//       icon: icons.IconDashboard,
//       breadcrumbs: false
//     }
//   ]
// };


const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    url: '/dashboard',
    icon: icons.IconDashboard,
    breadcrumbs: false
};


export default dashboard;
