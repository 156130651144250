// material-ui
import React, {useEffect, useState} from "react"
import Confetti from 'react-confetti'
// assets
import {IconBell, IconMailbox, IconPhoto, IconUser} from '@tabler/icons';


import {styled, useTheme} from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../../../fetcher";

// styles
const ListItemWrapper = styled('div')(({theme}) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

function NotificationList() {

    const [candidates, setCandidates] = useState([]);
    const [candidateToNotify, setCandidateToNotify] = useState({});
    const [candidateNewCVToNotify, setCandidateNewCVToNotify] = useState({});
    const [confetti, setConfetti] = useState(false);

    const theme = useTheme();

    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch(`${API_URL}/views/candidates`);
            const body = await resp.json();

            if (body.status !== 200) {
                console.log("No users yet");
            }

            setCandidates(body.candidates);
            if (body.candidates.length) {
                setCandidateToNotify(body.candidates.slice(-1)[0])
                setCandidateNewCVToNotify(body.candidates[0])
            }
            // setIsLoading(false);
            // setError(body.error);
            // setHasLinkedinAccount(body.has_linkedin_account)
            // setProfileLinkedinIds(body.candidates.map((candidate) => {
            //     return candidate.linkedin_profile_id
            // }));
        };

        fetchData();

    }, []);


    useEffect(() => {
        // Set a timer to hide confetti after 5 seconds
        const timer = setTimeout(() => {
            setConfetti(false);
        }, 3500); // 5000 milliseconds = 5 seconds

        // Clean up the timer
        return () => clearTimeout(timer);
    }, [confetti]); // Empty dependency array ensures this runs once on mount


    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.success.light,
        height: 28
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {/*<ListItemWrapper>*/}
            {/*    <ListItem alignItems="center">*/}
            {/*        <ListItemAvatar>*/}
            {/*            <Avatar alt={candidateToNotify.name} src={candidateToNotify.linkedin_profile_image_url}/>*/}
            {/*        </ListItemAvatar>*/}
            {/*        <ListItemText primary="New Position!"/>*/}
            {/*        /!* <ListItemText primary={<Typography variant="subtitle1">{candidateToNotify.name} started a new position as {candidateToNotify.linkedin_profile_last_position}</Typography>}/> *!/*/}
            {/*        <ListItemSecondaryAction>*/}
            {/*            <Grid container justifyContent="flex-end">*/}
            {/*                <Grid item xs={12}>*/}
            {/*                    <Typography variant="caption" display="block" gutterBottom>*/}
            {/*                        2 min ago*/}
            {/*                    </Typography>*/}
            {/*                </Grid>*/}
            {/*            </Grid>*/}
            {/*        </ListItemSecondaryAction>*/}
            {/*    </ListItem>*/}
            {/*    <Grid container direction="column" className="list-container">*/}
            {/*        <Grid item xs={12} sx={{pb: 2}}>*/}
            {/*            <Typography variant="subtitle2">*/}
            {/*                {candidateToNotify.name} started a new position*/}
            {/*                as {candidateToNotify.linkedin_profile_last_position}*/}
            {/*            </Typography>*/}
            {/*        </Grid>*/}
            {/*        /!* <Grid item xs={12}> *!/*/}
            {/*        /!*    <Grid container> *!/*/}
            {/*        /!*        <Grid item> *!/*/}
            {/*        /!*            <Chip label="Unread" sx={chipErrorSX}/> *!/*/}
            {/*        /!*        </Grid> *!/*/}
            {/*        /!*        /!* <Grid item> *!/ *!/*/}
            {/*        /!*        /!*    <Chip label="New" sx={chipWarningSX}/> *!/ *!/*/}
            {/*        /!*        /!* </Grid> *!/ *!/*/}
            {/*        /!*    </Grid> *!/*/}
            {/*        /!* </Grid> *!/*/}
            {/*    </Grid>*/}
            {/*</ListItemWrapper>*/}
            {/* <Divider/> */}
            {/* <ListItemWrapper> */}
            {/*    <ListItem alignItems="center"> */}
            {/*        <ListItemAvatar> */}
            {/*            <Avatar */}
            {/*                sx={{ */}
            {/*                    color: theme.palette.success.dark, */}
            {/*                    backgroundColor: theme.palette.success.light, */}
            {/*                    border: 'none', */}
            {/*                    borderColor: theme.palette.success.main */}
            {/*                }} */}
            {/*            > */}
            {/*                <IconBuildingStore stroke={1.5} size="1.3rem"/> */}
            {/*            </Avatar> */}
            {/*        </ListItemAvatar> */}
            {/*        /!* <ListItemText primary={<Typography variant="subtitle1">Store Verification Done</Typography>} /> *!/ */}
            {/*        <ListItemSecondaryAction> */}
            {/*            <Grid container justifyContent="flex-end"> */}
            {/*                <Grid item xs={12}> */}
            {/*                    <Typography variant="caption" display="block" gutterBottom> */}
            {/*                        2 min ago */}
            {/*                    </Typography> */}
            {/*                </Grid> */}
            {/*            </Grid> */}
            {/*        </ListItemSecondaryAction> */}
            {/*    </ListItem> */}
            {/*    <Grid container direction="column" className="list-container"> */}
            {/*        <Grid item xs={12} sx={{pb: 2}}> */}
            {/*            <Typography variant="subtitle2">We have successfully received your request.</Typography> */}
            {/*        </Grid> */}
            {/*        <Grid item xs={12}> */}
            {/*            <Grid container> */}
            {/*                <Grid item> */}
            {/*                    <Chip label="Unread" sx={chipErrorSX}/> */}
            {/*                </Grid> */}
            {/*            </Grid> */}
            {/*        </Grid> */}
            {/*    </Grid> */}
            {/* </ListItemWrapper> */}
            <Divider/>
            <ListItemWrapper
                onMouseEnter={() => setConfetti(true)}
                onMouseLeave={() => setConfetti(false)}>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar
                            sx={{
                                color: theme.palette.primary.dark,
                                backgroundColor: theme.palette.primary.light,
                                border: 'none',
                                borderColor: theme.palette.primary.main
                            }}
                        >
                            <IconBell stroke={1.5} size="1.3rem"/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">Weekly Update</Typography>}/>
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Typography variant="caption" display="block" gutterBottom>
                                    08:00
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                <Grid container direction="column" className="list-container"
                >
                    {/* <ConfettiExplosion  /> */}
                    {/* <Confetti /> */}
                    {confetti && <Confetti/>}
                    <Grid item xs={12} sx={{pb: 2}}>
                        <Typography variant="subtitle2">
                            We have retrieved 2 new CVs from your Gmail account and distributed them to 6 positions!
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item>
                                <Button variant="contained" disableElevation
                                        endIcon={<IconUser stroke={1.5} size="1.3rem"/>}
                                        onClick={() => navigate('../candidates', {replace: true})}
                                >
                                    Candidates Page
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </ListItemWrapper>
            <Divider/>
            <ListItemWrapper>
                <ListItem alignItems="center">
                    <ListItemAvatar>
                        <Avatar><IconMailbox stroke={1.5} size="1.3rem"/></Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={<Typography variant="subtitle1">New CV</Typography>}/>
                    <ListItemSecondaryAction>
                        <Grid container justifyContent="flex-end">
                            <Grid item xs={12}>
                                <Typography variant="caption" display="block" gutterBottom>
                                    22 May 2024
                                </Typography>
                            </Grid>
                        </Grid>
                    </ListItemSecondaryAction>
                </ListItem>
                {Object.keys(candidateNewCVToNotify).length > 0 && (
                    <Grid container direction="column" className="list-container">
                        <Grid item xs={12} sx={{pb: 2}}>
                            <Typography component="span" variant="subtitle2">
                                {Object.keys(candidateNewCVToNotify).length > 0 ? `We have received ${candidateNewCVToNotify.name}'s CV, and uploaded it to ${candidateNewCVToNotify.destinations[0].destination_company_name} and ${candidateNewCVToNotify.destinations.length - 1} additional companies` : ''}
                                {/* <Typography component="span" variant="h6"> */}
                                {/*    21 Nov 2023 */}
                                {/* </Typography> */}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            backgroundColor: theme.palette.secondary.light
                                        }}
                                    >
                                        <CardContent>
                                            <Grid container direction="column">
                                                <Grid item xs={12}>
                                                    <Stack direction="row" spacing={2}>
                                                        <IconPhoto stroke={1.5} size="1.3rem"/>
                                                        <a href={`/views/download_cv?candidate_source_id=${candidateNewCVToNotify.destinations[0].candidate_source_id}&user_connector_id=${candidateNewCVToNotify.user_connector_id}`}>
                                                            <Typography
                                                                variant="subtitle1">{candidateNewCVToNotify.destinations[0].cv_file_name}</Typography>
                                                        </a>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                )}
            </ListItemWrapper>
            <Divider/>
            {/* <ListItemWrapper> */}
            {/*    <ListItem alignItems="center"> */}
            {/*        <ListItemAvatar> */}
            {/*            <Avatar alt={candidateToNotify.name} src={candidateToNotify.linkedin_profile_image_url}/> */}
            {/*        </ListItemAvatar> */}
            {/*        <ListItemText primary={<Typography variant="subtitle1">{candidateToNotify.name} started a new position as {candidateToNotify.linkedin_profile_last_position}</Typography>}/> */}
            {/*        <ListItemSecondaryAction> */}
            {/*            <Grid container justifyContent="flex-end"> */}
            {/*                <Grid item xs={12}> */}
            {/*                    <Typography variant="caption" display="block" gutterBottom> */}
            {/*                        2 min ago */}
            {/*                    </Typography> */}
            {/*                </Grid> */}
            {/*            </Grid> */}
            {/*        </ListItemSecondaryAction> */}
            {/*    </ListItem> */}
            {/*    /!* <Grid container direction="column" className="list-container"> *!/ */}
            {/*    /!*    <Grid item xs={12} sx={{pb: 2}}> *!/ */}
            {/*    /!*        <Typography variant="subtitle2">It is a long established fact that a reader will be *!/ */}
            {/*    /!*            distracted</Typography> *!/ */}
            {/*    /!*    </Grid> *!/ */}
            {/*    /!*    <Grid item xs={12}> *!/ */}
            {/*    /!*        <Grid container> *!/ */}
            {/*    /!*            <Grid item> *!/ */}
            {/*    /!*                <Chip label="Confirmation of Account." sx={chipSuccessSX}/> *!/ */}
            {/*    /!*            </Grid> *!/ */}
            {/*    /!*        </Grid> *!/ */}
            {/*    /!*    </Grid> *!/ */}
            {/*    /!* </Grid> *!/ */}
            {/* </ListItemWrapper> */}
        </List>
    );
}

export default NotificationList;
