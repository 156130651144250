import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Outlet, useNavigate} from 'react-router-dom';
// material-ui
import {styled, useTheme} from '@mui/material/styles';
import {AppBar, Box, CssBaseline, Toolbar, useMediaQuery} from '@mui/material';

// project imports
import {IconChevronRight} from '@tabler/icons';
import Breadcrumbs from '../../ui-component/extended/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
// import Customization from '../Customization';
import navigation from '../../menu-items';
import {drawerWidth} from '../../store/constant';
import {SET_MENU} from '../../store/actions';
import {API_URL} from "../../fetcher";

// assets

// styles
const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    ...theme.typography.mainContent,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: theme.transitions.create(
        'margin',
        open
            ? {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen
            }
            : {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
            }
    ),
    [theme.breakpoints.up('md')]: {
        marginLeft: open ? 0 : -(drawerWidth - 20),
        width: `calc(100% - ${drawerWidth}px)`
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: '20px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px'
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: '10px',
        width: `calc(100% - ${drawerWidth}px)`,
        padding: '16px',
        marginRight: '10px'
    }
}));

// ==============================|| MAIN LAYOUT ||============================== //
const fetcher = (...args) => fetch(...args).then(res => {
    res
})


function MainLayout() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    // Handle left drawer
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({type: SET_MENU, opened: !leftDrawerOpened});
    };
    const navigate = useNavigate();


    useEffect(() => {

        const fetchData = async () => {
            try {
                const resp = await fetch(`${API_URL}/auth/validate`);
                const body = await resp.json();

                if (body.status !== 200) {
                    setIsLoggedIn(false);
                    navigate('../login', {replace: true});
                } else {
                    setIsLoggedIn(true);
                    if (window.location.pathname === '/') {
                        navigate('../onboarding', {replace: true});
                    }

                }
            } catch (error) {
                console.log("error", error);
                // setIsLoading(false);
                setIsLoggedIn(false);
                navigate('../login', {replace: true});
            }
            setIsLoading(false);
        };

        fetchData();
    }, []);

    if (isLoading || !isLoggedIn) {
        return
    }


    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            {/* header */}
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle}/>
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened}
                     drawerToggle={handleLeftDrawerToggle}/>

            {/* main content */}
            <Main theme={theme} open={leftDrawerOpened}>
                {/* breadcrumb */}
                <Breadcrumbs separator={IconChevronRight} navigation={navigation} icon title rightAlign/>
                <Outlet/>
            </Main>
            {/* <Customization /> */}
        </Box>
    );
}

export default MainLayout;
