// assets
import {
    Icon360View,
    IconDeviceTvOld
} from '@tabler/icons';


// ==============================|| UTILITIES MENU ITEMS ||============================== //

// const onboarding = {
//   id: 'onboarding',
//   title: 'Onboarding',
//   type: 'group',
//   children: [
//     {
//       id: 'onboarding-guide',
//       title: 'Onboarding Guide',
//       type: 'item',
//       url: '/onboarding',
//       icon: Icon360View,
//       // icon: IconDeviceTvOld,
//       breadcrumbs: false
//     },
//   ]
// };

const onboarding = {
    id: 'onboarding',
    title: 'Onboarding',
    type: 'item',
    url: '/onboarding',
    icon: Icon360View,
    breadcrumbs: false
};

export default onboarding;
